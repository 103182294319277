//import * as React from "react"
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBO1UUMShH4odWsVhcttpw43VkbzJpeO3E",
    authDomain: "looptoapollo.firebaseapp.com",
    projectId: "looptoapollo",
    storageBucket: "looptoapollo.appspot.com",
    messagingSenderId: "444679691606",
    appId: "1:444679691606:web:233ebdee15dbaf36020af7",
    measurementId: "G-9XZ9KYPNRP"
};
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
const auth = fb.auth()
const store = fb.firestore()
const storage = fb.storage()


 export {auth,store,storage}